.nav {
}

.navbar-brand img {
  margin-left: 50px;
  width: 100%;
  height: 116px;
  margin-top: 50px;
  margin-bottom: 25px;
}

@media (max-width:768px) {
    .navbar-brand img  {
       margin-top: 20px;
       margin-left: 10px;
       width: 100%;
       height: 50px;
    }
}

label {
  display: block;
  margin-top: 10px;
}

.nav-root {
  padding-top: 20px;
  margin-right: -10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.login {
  margin-top: 10px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.activity-header {
  font-family: var(--bs-font-sans-serif)
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

* {
  box-sizing: border-box
}

.img-container {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.img-container-overlay {
  position: absolute;
  bottom: 0;
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, 0.5); /* Black see-through */
  color: #f1f1f1;
  width: 400px;
  margin-bottom: 50px;
  transition: .5s ease;
  opacity:0;
  color: white;
  font-size: 15px;
  padding: 20px 20px 20px;
  text-align: center;
  opacity: 1;
}

@media (min-width:0px) {
    .img-container-overlay {
      width: 360px;
      padding: 20px 20px 15px;
      font-size: 15px;
    }
}

@media (min-width:768px) {
    .img-container-overlay {
      width: 300px;
      padding: 3px 15px 3px;
      font-size: 13px;
    }
}

@media (min-width:992px) {
    .img-container-overlay {
      width: 400px;
      padding: 20px 20px 15px;
      font-size: 15px;
    }
}


@media (min-width:0px) {
    .activity-img {
      margin-top: 10px;
      margin-bottom: 50px;
      border-radius: 3px;
      width: 360px;
      height: 280px;
    }
}

@media (min-width:768px) {
    .activity-img {
      margin-top: 10px;
      margin-bottom: 50px;
      border-radius: 3px;
      width: 300px;
      height: 200px
    }
}

@media (min-width:992px) {
    .activity-img {
      margin-top: 10px;
      margin-bottom: 50px;
      border-radius: 3px;
      width: 400px;
      height: 300px
    }
}

.collection-header {
  padding-top: 50px;
  align-self: center;
  text-align: center;
}

.extra-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
}
.extra-info-free-banner-container {
  width: 15%;
  float: left;
}

.extra-info-ratings-container {
  width: 50%;
  margin-top: -2px;
}

.extra-info-age-range-container {
  width: 35%;
}

/** ratings **/
.ratings-count {
  font-size: 11px;
}

/** Is Free Banner **/
.free-banner-div {
  background-color: #D9F6F3;
  width: 45px;
  height: 22px;
  border-radius: 5px;
}

.free-banner-div p {
  color: #3F3F3F;
  font-size: 12px;
  padding-top: 2px;
}

/** Age range **/
.age-range-container {
  background: linear-gradient(to right, #00C8B4 28%, #D9F6F3 0%);
  width: 100px;
  height: 22px;
  border-radius: 5px;
}

.age-range-icon {
  padding-left: 5px;
  margin-top: -2px;
  float: left;
}
.age-range-container p {
  color: #3F3F3F;
  font-size: 12px;
  padding-top: 2px;
}

.center {
  justify-content: center !important;
}

.submit-spinner {
   justify-content: center !important;
   display: inline !important;
}

.ios-store-link {
  background: url('https://linkmaker.itunes.apple.com/images/badges/en-us/badge_appstore-lrg.svg') 0% 0% / contain no-repeat;
  display: inline-block; 
  height: 60px; 
  width: 200px;
  margin-left: 12px;
  margin-top: 14px;
  margin-right: 20px;
}

.android-store-link {
  background: url('https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png') 0% 0% / contain no-repeat; 
  display: inline-block; 
  height: 88px; 
  width: 230px;
}

.footer {
  display: inline;
  align-content: center;
  align-items: center;
  align-self: center;
  margin-bottom: 50px;
  margin-top: 50px;
}
.footer a {
  text-align: center;
  padding-bottom: 10px;
  align-content: center;
  align-items: center;
  align-self: center;
}

.link:hover {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.table-row:hover {
  cursor: pointer;
}

.footer {
  padding-top: 50px !important;
}

.footer p {
  text-align: center;
}

.spinner {
  width: 3rem; height: 3rem;
}
.form-floating {
  padding-bottom: 50px;
}
.form-floating > label {
    margin-top: -5px !important;
    color: #555;
}

.form-floating > textarea {
  height: 250px !important;
  line-height: 1.75 !important;
}
.headline-photo {
  text-align: center;
  padding-bottom: 10px;
  align-content: center;
  align-items: center;
  align-self: center;
}

hr {
  padding-top: 30px;
}

.thumbnail-image {
  width: 250px;
  height: 150px;
  padding: 10px;
  border-radius: 20px;
}

.headline {
  border-right: 1px;
  padding: 10px;
  border-color: grey;
  align-self: center;
  align-items: center;
  border-radius: 10px;
  margin-right: 50px;
  margin-left: -30px;
}

.additional {
  padding-top: 10px;
}

.additional-photos-container {
  align-self: center;
  align-items: center;
  padding: 10px;
  position: absolute;
}

.form-action-buttons {
  padding-top: 5px;
}

.action-save {
  margin-right: 15px;
}

.action-cancel {
  margin-left: 5px;
}

.ratings-list-container {
  text-align: center;
}

.align-middle {
  text-align: center;
}

.place-save-actions {
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  align-content: center;
}

.validation-error-label {
  color: #dc3545 !important;
}

.model-body-text > p {
  text-align: center;
}

.file-uploader {
  height: 150px !important;
  width: 80% !important;
}

.non-heading {
  padding-left: 5px;
}

.image {
  background: rgba(255,255,255,0.5);
}

.trash-container {
  justify-content: center;
  align-items: center;
  text-align: center;
  align-self: center;
  align-content: center;
  padding-bottom: 10px;
  margin-top: -8px;
}

.trash-icon {
  color: grey;
}

.trash-icon:hover {
  color: black;
  cursor: pointer;
}

.alert-banner {
  padding-top: 10px;
}

.text-limit {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: flex-end;
  align-self: flex-end;
  align-content: flex-end;
  margin-top: -30px;
  padding-right: 10px;
}

.category-row {
  padding-bottom: 10px;
}

.facilities-row {
  padding-bottom: 10px;
}

.button-text {
  font-size: 0.8rem;
}

.filepond--item {
    width: calc(50% - 0.5em) !important;
}